import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Em, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				OceanDream Charters
			</title>
			<meta name={"description"} content={"Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.\n"} />
			<meta property={"og:title"} content={"OceanDream Charters"} />
			<meta property={"og:description"} content={"Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Components.Marquee />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Дізнайтеся більше про OceanDream Charters
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Наш розкішний флот, експертний екіпаж та індивідуальні послуги. Відкрийте для себе наш асортимент пропозицій і почніть планувати ідеальний чартер на яхті.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-2.jpg?v=2024-08-12T08:11:48.152Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Наша Історія: Пристрасть до Моря
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				OceanDream Charters було засновано з глибокою пристрастю до моря і прагненням забезпечити неперевершений досвід на воді. Наші засновники, досвідчені моряки з багаторічним досвідом, уявляли компанію, яка поєднує розкіш приватних яхт із свободою досліджувати найкрасивіші місця світу. Сьогодні OceanDream Charters є лідером у галузі чартерів яхт, відомою своїм винятковим обслуговуванням, світовим класом флоту та відданістю до створення незабутніх подорожей.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="block"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="--lead" color="#b2bcc3">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Наш Флот
								</Strong>
								<br />
								<Em
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									{" "}Де Розкіш Зустрічається з Продуктивністю
									<br />
									<br />
								</Em>
							</Span>
							{" "}В OceanDream Charters ми пишаємося нашим різноманітним флотом яхт, кожна з яких створена для максимального комфорту, стилю та продуктивності. Від сучасних моторних яхт до класичних вітрильників, наша колекція підходить для будь-яких уподобань і розмірів груп.
							<br />
							{" "}
							<br />
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Кожна яхта оснащена:{" "}
								<br />
							</Em>
							Просторими та елегантно оформленими каютами
					Сучасними розважальними системами{"\n"}
							<br />
							{"\t\t\t\t\t"}Повністю обладнаними кухнями з гурманськими приладами{"\n"}
							<br />
							{"\t\t\t\t\t"}Новітніми навігаційними та безпековими пристроями
					Зонами для відпочинку та харчування на палубі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="--lead" color="#b2bcc3">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Наш Екіпаж
								</Strong>
								<br />
								<Em
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Ваші Господарі на Високих Мореях
									<br />
									<br />
								</Em>
							</Span>
							Наші члени екіпажу є серцем OceanDream Charters. Вибрані за їхній професіоналізм, експертизу та пристрасть до гостинності, вони присвячені забезпеченню вам виняткового досвіду. З моменту, коли ви ступите на борт, наш екіпаж подбає про всі ваші потреби, забезпечуючи, що ваш час з нами буде ідеальним.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="--lead" color="#b2bcc3">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Наші Послуги
								</Strong>
								<br />
								<Em
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									{" "}Підлаштовані під Ваші Потреби
									<br />
								</Em>
							</Span>
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<br />
							</Em>
							В OceanDream Charters ми пропонуємо широкий спектр послуг, щоб покращити ваш досвід чартеру яхт.{" "}
							<br />
							Сюди входять:
					Індивідуальні маршрути: Створіть маршрути, які відображають ваші інтереси та вподобання.{"\n"}
							<br />
							{"\t\t\t\t\t"}Гурманське харчування: Варіанти харчування на борту зі стравами, приготованими найкращими шеф-кухарями.
					Обладнання для водних видів спорту: Доступ до гідроциклів, спорядження для снорклінгу, веслування на дошках тощо.
					Приватні заходи: Проведення весіль, корпоративних заходів чи вечірок в ексклюзивному місці.{"\n"}
							<br />
							{"\t\t\t\t\t"}Розкішні трансфери: VIP-транспорт до і від вашої яхти.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Наші яхти ретельно обслуговуються та регулярно оновлюються, щоб забезпечити відповідність найвищим стандартам розкоші та безпеки.
			</Text>
		</Section>
		<Components.Marquee />
		<Components.Footer1>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Footer1>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});